import axios from "axios";
import Cookies from "js-cookie";
import { useBetween } from "use-between";
import { responseStatus, TOPICS_LIST } from "../../../utils/consts";
import { generateJWSToken } from "../../../utils/mercureAuth";
import {
  formNotificationsArray,
  getStartingNotification,
  isVisibleNotifications,
  notificationsCookie,
  welcomeCookieName
} from "../../../utils/notifications";
import { TimestampToCustomFormat } from "../../../utils/timestampToDate";
import userAuthenticationConfig from "../../../utils/userAuthenticationConfig";
import LanguageStates from "../listLanguage/LanguageStates";
import { closableNotification } from "../notification/ClosableNotification";
import {
  StyledNotification,
  StyledNotificationsButton,
  StyledNotificationsWrapper,
  StyledRightSidebar
} from "./styledRightSidebar";
import React, { useContext, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import moment from "moment";
import notificationSounds from "../../../assets/sounds/newmessage.mp3";
import UserTag, { tagRegex as userTagRegex } from "../tags/UserTag";
import { renderToStaticMarkup } from "react-dom/server";
import BetTag, { tagRegex as betTagRegex } from "../tags/BetTag";
import { AppContext, MercureUrl } from "../../../App";

export const RightSidebar = () => {

  const { t } = useTranslation("siteOptions");
  const [notifications, setNotifications] = useState([]);
  const [isNotificationsVisible, setIsNotificationsVisible] = useState((Cookies.get(isVisibleNotifications) && Cookies.get(isVisibleNotifications) !== "undefined") ? JSON.parse(Cookies.get(isVisibleNotifications)) : true);
  const [audio] = useState(new Audio(notificationSounds));
  const [notifiedIds, setNotifiedIds] = useState(() => {
    const storedIds = localStorage.getItem('notifiedIds');
    return (storedIds && storedIds !== "undefined") ? JSON.parse(storedIds) : [];
  });

  const { user, authenticated } = useContext(AppContext);
  const { activeLang } = useBetween(LanguageStates);

  const saveNotifiedIds = (ids) => {
    localStorage.setItem('notifiedIds', JSON.stringify(ids));
    setNotifiedIds(ids);
  };

  const fetchNotifications = () => {
    axios.get("/api/notifications", userAuthenticationConfig()).then(response => {
      if (response.status === responseStatus.HTTP_OK && response.data) {
        setNotifications(old => formNotificationsArray(old, response.data));
      }
    }).catch(error => {
      closableNotification(error.response.data.message, "error");
    });
  };

  const deleteNotification = (id) => {
    axios.delete("/api/notifications/" + id, userAuthenticationConfig()).then(response => {
      if (response.status === responseStatus.HTTP_OK) {
        setNotifications(old => formNotificationsArray(old.filter(notification => notification.id !== id), response.data));
      }
    }).catch(error => {
      closableNotification(error.response.data.message, "error");
    });
  };

  const handleCloseNotification = (event, id, type = null) => {
    setNotifications(old => old.map((notification) => {
      if (notification.id === id) {
        notification.className.push("hidden");
      }
      const index = notification.className.indexOf("new");
      if (index >= 0) {
        notification.className.splice(index, 1);
      }
      return notification;
    }));

    if (type && type === "welcome") {
      Cookies.set(welcomeCookieName, true, { path: "" });
      setTimeout(() => {
        setNotifications(old => old.filter(notification => notification.id !== id));
      }, 200);
      return;
    }

    deleteNotification(id);
  };

  const handleMinimizeNotification = (event, id) => {
    const newNotifications = notifications.map((notification) => {
      if (notification.id === id) {
        const index = notification.className.indexOf("minimized");
        if (index >= 0) {
          notification.className.splice(index, 1);
        } else {
          notification.className.push("minimized");
        }
      }
      return notification;
    });

    setNotifications(newNotifications);

    Cookies.set(notificationsCookie, JSON.stringify(newNotifications));
  };

  const addNotification = (notification, isEnabledAudio = true) => {
    notification.className = ["new"];
    setNotifications(old => formNotificationsArray(old, [notification]));

    if (!notifiedIds.includes(notification.id)) {
      audio.play().catch(() => {});
      saveNotifiedIds([...notifiedIds, notification.id]);
    }
  };

  useEffect(() => {
    if (!Cookies.get(welcomeCookieName) || Cookies.get(welcomeCookieName) === "undefined" || Cookies.get(welcomeCookieName) !== "true") {
      const welcomeNotification = getStartingNotification(t);
      addNotification(welcomeNotification);
    }

  }, []);

  const handleOfflineNotifications = () => {
    let welcomeNotification = null;

    if (!Cookies.get(welcomeCookieName) || Cookies.get(welcomeCookieName) === "undefined" || Cookies.get(welcomeCookieName) !== "true") {
      welcomeNotification = getStartingNotification(t);
    }

    setNotifications(formNotificationsArray([], [welcomeNotification]));
  };

  const processBeforeOutput = (content) => {
    if (typeof content === "string") {
      content = content.replace(userTagRegex, (substr, username) =>
        renderToStaticMarkup(<UserTag username={username} isCurrentUser={user?.nickname === username} />));
      content = content.replace(betTagRegex, (substr, id) =>
        renderToStaticMarkup(<BetTag id={id} />));
    }
    return content;
  };

  const hideNotifications = () => {
    const newVisibleState = !isNotificationsVisible;

    setIsNotificationsVisible(newVisibleState);

    Cookies.set(isVisibleNotifications, newVisibleState);
  };

  const updateMercureNotification = (data) => {
    addNotification(data);
  };

  const topic = TOPICS_LIST.NOTIFICATION.TOPIC + user?.userId;
  const token = generateJWSToken(topic);

  useEffect(() => {
    if (!authenticated) {
      return;
    }

    MercureUrl.searchParams.delete("topic");

    MercureUrl.searchParams.append("topic", topic);

    Cookies.set("mercureAuthorization", token, { path: "" });

    const es = new EventSource(MercureUrl, { withCredentials: true });

    es.addEventListener("message", (event) => {
      let dataMercure = JSON.parse(event.data ?? null);
      updateMercureNotification(dataMercure);
    });

    return () => {
      es.close();
    };
  }, [authenticated]);

  useEffect(() => {
    if (!authenticated) {
      handleOfflineNotifications();
      return;
    }

    setNotifications([]);

    fetchNotifications();
  }, [authenticated, activeLang]);

  const notificationsComponent = useMemo(() => {
    return notifications.map((notification, key) => {
      return <StyledNotification
        key={key}
        animationDuration={200}
        id={`notification_${notification.id}`}
        className={notification.className.join(" ") ?? ""}
      >
        <div className={"options"}>
          <div className={"minimize"} onClick={e => handleMinimizeNotification(e, notification.id)}>–</div>
          <div className={"close"} onClick={e => handleCloseNotification(e, notification.id, notification?.type)}>×
          </div>
        </div>
        <div className={"notification-title"}>{notification.title}</div>
        <div className={"notification-content"}
             dangerouslySetInnerHTML={{ __html: processBeforeOutput(notification.content) }}
        />
        <div className={"notification-time"}>{TimestampToCustomFormat(notification.createdAt / 1000)}</div>
      </StyledNotification>;
    });
  }, [notifications]);

  return <>
    {notifications.length > 0 ?
      <StyledRightSidebar>
        {isNotificationsVisible && notifications.length > 0 && <StyledNotificationsWrapper>
          {notificationsComponent}
        </StyledNotificationsWrapper>}
        <StyledNotificationsButton
          onClick={hideNotifications}
          className={isNotificationsVisible || notifications.length > 0 ? "active" : ""}
        >
          {isNotificationsVisible ? t("hideNotifications") : t("showNotifications")}
          {!isNotificationsVisible &&
            <span className={"notifications-counter"}>{notifications.length}</span>
          }
        </StyledNotificationsButton>
      </StyledRightSidebar> :
      null
    }
  </>
};
