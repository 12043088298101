import React from "react";
// import { useTranslation } from 'react-i18next'
import {NavLink} from "react-router-dom";
import {generatePath} from "../../../utils/getLanguage";
import {TimestampToCustomFormat} from "../../../utils/timestampToDate";
import {StyledHistoryBet, StyledHistoryDate, StyledHistoryLink} from "../../styles/styledHistory";
import {StyledPaymentMethodsIcons} from "../../styles/StyledPaymentMethodsIcons";
// import { StyledDiceHistoryProgress } from './styledDice'
import {closableNotification} from "../../elements/notification/ClosableNotification";
import {useTranslation} from "react-i18next";

const RouletteBetsListItem = React.memo((props) => {
    const {value, setSnapshotBetData} = props;
    const {t} = useTranslation("siteOptions");

    function copyCodeBanner() {
        navigator.clipboard.writeText(value.id).then(() => {
            closableNotification(t("copyIdBetNotificationSuccess"), "success")
        }).catch((err) => {
            closableNotification(t("copyReferralLinkNotificationError"), "error")
        });
    }

    return (
        <tr>
            <td data-title="ID">
                <StyledHistoryLink as={NavLink} to={`/bet/${value.id}`}>
                    <div>
                        {new Intl.NumberFormat("en-US").format(value.id)}
                    </div>
                </StyledHistoryLink>
            </td>
            <td data-title="Время / Игрок">
                <StyledHistoryDate>
                    {TimestampToCustomFormat(value.createdAt)}
                </StyledHistoryDate>
                <StyledHistoryLink as={NavLink} to={generatePath(`/account/${value.user.nickname}`)}>
                    <div>
                        {value.user.nickname}
                    </div>
                </StyledHistoryLink>
            </td>
            <td data-title="Валюта" className="roulette-history-table__currency">
                <StyledPaymentMethodsIcons className={`payment-method-${value.paymentMethod.currency.asset}`}/>
            </td>
            <td data-title="Выпавший номер" className="roulette-history-table__number">
                {value.drawnNumber}
            </td>
            <td data-title="Ставка">
                <StyledHistoryBet losing={parseFloat(value.lose) === 0}>
                <span>
                  {value.gameObject.chanceToWin}%
                </span>
                    {value.bet} {value.paymentMethod.currency.asset}
                </StyledHistoryBet>
            </td>
            <td data-title="Выигрыш">
                <StyledHistoryBet losing={parseFloat(value.lose) === 0}>
                <span>
                  {parseFloat(value.lose) === 0 ? ((parseFloat(value.bet) + parseFloat(value.win)) / parseFloat(value.bet)).toFixed(4) : 0}x
                </span>
                    {parseFloat(value.lose) === 0 ? (parseFloat(value.bet) + parseFloat(value.win)).toFixed(8) : (0).toFixed(8)} {value.paymentMethod.currency.asset}
                </StyledHistoryBet>
            </td>
            <td data-title="Опции" className="roulette-history-table__options">
                <div onClick={() => setSnapshotBetData(value)}>
                    <svg height="20" fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100">
                        <path
                            d="M50 100c27 0 50-23 50-50S77 0 50 0 0 23 0 50s23 50 50 50Zm0-8C27 92 8 73 8 50S27 8 50 8a42 42 0 1 1 0 84Zm-9-24 27-15c2-2 1-4 0-5L41 32c-2-1-4 0-4 2v32c0 2 2 3 4 2Z"
                        />
                    </svg>
                    <span>Watch</span>
                </div>
                <div onClick={() => copyCodeBanner()}>
                    <svg height="20" fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 82 100">
                        <path fill-rule="evenodd" clip-rule="evenodd"
                              d="M10 10c2-6 7-10 13-10h46c7 0 13 6 13 13v60c0 7-6 13-13 13h-5v-7h5c3 0 6-3 6-6V13c0-3-3-6-6-6H22c-2 0-4 1-5 3h-7Z"
                              />
                        <path
                            d="M69 33 50 15l-3-1H12C7 14 0 20 0 26v62c0 6 7 12 12 12h46c5 0 12-6 12-12V36l-1-3Zm-6 55c0 3-2 5-5 5H12c-3 0-5-2-5-5V26c0-3 2-6 5-6h32v8c0 6 5 10 11 10h8v50Z"
                            />
                        <rect x="13" y="39" width="30" height="8" rx="4" />
                        <rect x="13" y="56" width="44" height="8" rx="4" />
                        <rect x="13" y="73" width="44" height="8" rx="4" />
                    </svg>
                    <span>Copy</span>
                </div>
            </td>
        </tr>
    );
});

export default RouletteBetsListItem;
