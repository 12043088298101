import React, {useImperativeHandle, useRef, useCallback, useEffect, useState, useContext} from "react";
import { useTranslation } from "react-i18next";
// import { animated, easings, useSpring, useSprings, to } from "@react-spring/web";
import { closableNotification } from "../../elements/notification/ClosableNotification";
import { chipsRef } from './constants';
import {
  StyledRouletteConstWrapper,
  StyledRouletteItem,
  StyledRouletteNoOverlay,
  StyledRouletteNoOverlayItem,
  // StyledRouletteNum,
  // StyledRouletteTable,
  StyledRedOrBlackIcon,
  StyledWinningBall,
} from "./styledRoulette";
// import Roulette from './Roulette';
import { formatChip } from './utils';
import ChipItem from './ChipItem';

import { useBetween } from "use-between";
import BalanceStates from "../BalanceStates";
import {playSound} from "../../../utils/checkSound";
import useSound from "use-sound";
import chipAddSound from "../../../assets/sounds/roulette/chip-add.mp3";
import chipRemoveSound from "../../../assets/sounds/roulette/chip-remove.mp3";
import {siteSound} from "../../../utils/consts";
import {AppContext} from "../../../App";

const RouletteTable = React.forwardRef(({
  submitData,
  setSubmitData,
  // responseData,
  textRates,
  // isRotate,
  // setIsRotate,
  autoModeIsStart,
  isPlay,
  orientation,
  selectedNumber,
  isMobile,
  isOverlay,
  isSnapshot = false
}, ref) => {
  const { t } = useTranslation("games");
  const { activeCurrency } = useBetween(BalanceStates);
  const { authenticated } = useContext(AppContext);
  const { soundMode } = useContext(AppContext);
  const { sound } = soundMode;
  const rouletteRef = useRef();
  const [playChipAddSound] = useSound(chipAddSound, { interrupt: true });
  const [playChipRemoveSoundSound] = useSound(chipRemoveSound, { interrupt: true });

  const addChipRef = useCallback((ref, id) => {
    chipsRef.current[id] = ref;
  }, []);

  const selectNumbers = useCallback((value, isClear) => {
    if (isSnapshot || !authenticated) return;
    if (!isPlay && !autoModeIsStart && selectedNumber === undefined) {
      if (submitData.currentBet === "0") {
        closableNotification(t('betNotValid'), "warning");
        return;
      }

      let tmp = submitData.suggestedNumbers;

      if (submitData.currentBet === "clear" || isClear) {
        if (tmp[value] && sound === siteSound.ON) playChipRemoveSoundSound();
        hoverEffect(value, false);
        delete tmp[value];
        const rouletteItem = document.querySelector(`[data="${value}"]`);
        if (rouletteItem) {
          rouletteItem.classList.remove("hover");
        }
      } else if (Object.keys(tmp).length === 0 || !tmp.hasOwnProperty(value)) {
        tmp[value] = Number(submitData.currentBet);
        if (sound === siteSound.ON) playChipAddSound();
      } else {
        tmp[value] = Number(tmp[value]) + Number(submitData.currentBet);
        if (sound === siteSound.ON) playChipAddSound();
      }

      let sumOfAllBets = Number(Object.keys(tmp).reduce((acc, cur) => acc + tmp[cur], 0));

      setSubmitData((prevState) => ({
        ...prevState,
        suggestedNumbers: tmp,
        bet: sumOfAllBets.toString()
      }));
    }
  }, [isPlay, setSubmitData, t, submitData?.suggestedNumbers, submitData?.currentBet, autoModeIsStart, selectedNumber, playChipAddSound, playChipRemoveSoundSound, sound]);

  const chipColor = useCallback((value) => {
    const chipsValue = parseFloat((value / 0.00000001).toFixed(8));
    if (chipsValue >= 0 && chipsValue < 1000000) {
      return "red";
    } else if (chipsValue >= 1000000 && chipsValue < 1000000000000) {
      return "green";
    } else if (chipsValue >= 1000000000000) {
      return "yellow";
    }
  }, []);

  const hoverEffect = useCallback((value, hover, hoverThis = false) => {
    if (isSnapshot) return;
    let rouletteItem;
    if (hoverThis) {
      rouletteItem = document.querySelectorAll(`[data="${value}"]`);
      if (rouletteItem.length > 1) {
        rouletteItem = rouletteItem[0];
      }
      if (rouletteItem) {
        if (hover) {
          if (rouletteItem.classList) {
            rouletteItem.classList.add("hover");
          }
        } else {
          if (rouletteItem.classList) {
            rouletteItem.classList.remove("hover");
          }
        }
      }
    }
    value.split(",").map((item) => {
      rouletteItem = document.querySelector(`[data="${item}"]`);
      if (hover) {
        rouletteItem.classList.add("hover");
      } else {
        rouletteItem.classList.remove("hover");
      }
    });
  }, []);

  const renderMainItem = useCallback((value) => {
    return (
      value.split(",").map((button) => (
        <StyledRouletteItem
          key={button}
          data={button}
          type="main"
          onClick={isOverlay ? () => selectNumbers(button) : null}
          onContextMenu={isOverlay ? () => selectNumbers(button, true) : null}
          onMouseOver={isOverlay ? () => hoverEffect(button, true) : null}
          onMouseOut={isOverlay ? () => hoverEffect(button, false) : null}
          isMobile={isMobile}
          isSnapshot={isSnapshot}
        >
          <span>{button}</span>
          {(isOverlay && submitData.suggestedNumbers[button]) &&
          <ChipItem
            // as="div"
            isMobile={isMobile}
            ref={(ref) => addChipRef(ref, button)}
            color={chipColor(submitData.suggestedNumbers[button])}
            className="chip"
            isSnapshot={isSnapshot}
            currency={submitData?.paymentMethod?.currency?.asset ?? null}
          >
            <span
              data-value={submitData.suggestedNumbers[button]}
            >
              {formatChip(submitData.suggestedNumbers[button], chipColor(submitData.suggestedNumbers[button]))}
            </span>
          </ChipItem>}
          {
            parseInt(selectedNumber) === parseInt(button) && (
              <StyledWinningBall
                isMobile={isMobile}
              >
                <span>
                {button}
                </span>
              </StyledWinningBall>
            )
          }
        </StyledRouletteItem>
      ))
    );
  }, [activeCurrency, addChipRef, isMobile, selectNumbers, submitData?.suggestedNumbers, selectedNumber, chipColor, isOverlay, hoverEffect]);

  const render2to1Item = useCallback((value, isLast) => {
    return (
      <StyledRouletteItem
        isMobile={isMobile}
        onMouseOver={isOverlay ? () => hoverEffect(value, true, true) : null}
        onMouseOut={isOverlay ? () => hoverEffect(value, false, true) : null}
        data={value}
        type="row"
        className={isLast ? 'last' : ''}
        onClick={isOverlay ? () => selectNumbers(value) : null}
        onContextMenu={isOverlay ? () => selectNumbers(value, true) : null}
        isSnapshot={isSnapshot}
      >
        <div>
          {
            !isOverlay ?
              !isMobile ? '2 to 1' : '2:1'
              : ''
          }
        </div>
        {(isOverlay && submitData.suggestedNumbers[value]) &&
        <ChipItem
          isMobile={isMobile}
          // as="div"
          ref={(ref) => addChipRef(ref, value)}
          color={chipColor(submitData.suggestedNumbers[value])}
          className="chip"
          isSnapshot={isSnapshot}
          currency={submitData?.paymentMethod?.currency?.asset ?? null}
          isLastChip={true}
        >
          <span
            data-value={submitData.suggestedNumbers[value]}
          >
            {formatChip(submitData.suggestedNumbers[value], chipColor(submitData.suggestedNumbers[value]))}
          </span>
        </ChipItem>}
      </StyledRouletteItem>
    );
  }, [activeCurrency, addChipRef, isMobile, selectNumbers, submitData?.suggestedNumbers, chipColor, hoverEffect, isOverlay]);

  const renderConstItem = useCallback((value, title, className) => {
    return (
      <StyledRouletteItem
        isMobile={isMobile}
        onMouseOver={isOverlay ? () => hoverEffect(value, true, true) : null}
        onMouseOut={isOverlay ? () => hoverEffect(value, false, true) : null}
        className={className}
        data={value}
        onClick={isOverlay ? () => selectNumbers(value) : null}
        onContextMenu={isOverlay ? () => selectNumbers(value, true) : null}
        isSnapshot={isSnapshot}
      >
        {!isOverlay ? title : ''}
        {(isOverlay && submitData.suggestedNumbers[value]) &&
        <ChipItem
          isMobile={isMobile}
          // as="div"
          ref={(ref) => addChipRef(ref, value)}
          color={chipColor(submitData.suggestedNumbers[value])}
          className="chip"
          isSnapshot={isSnapshot}
          currency={submitData?.paymentMethod?.currency?.asset ?? null}
        >
          <span
            data-value={submitData.suggestedNumbers[value]}
          >
            {formatChip(submitData.suggestedNumbers[value], chipColor(submitData.suggestedNumbers[value]))}
          </span>
        </ChipItem>}
      </StyledRouletteItem>
    );
  }, [activeCurrency, addChipRef, isMobile, selectNumbers, submitData?.suggestedNumbers, chipColor, hoverEffect, isOverlay]);

  const renderSplitItemH = useCallback((value) => {
    return (
      value.map((button, index) => (
        <StyledRouletteNoOverlayItem
          isMobile={isMobile}
          onMouseOver={isOverlay ? () => hoverEffect(button, true) : null}
          onMouseOut={isOverlay ? () => hoverEffect(button, false) : null}
          type="splitH"
          key={button}
          data={button}
          className={index === 0 ? 'first' : ''}
          onClick={isOverlay ? () => selectNumbers(button) : null}
          onContextMenu={isOverlay ? () => selectNumbers(button, true) : null}
        >
          {(isOverlay && submitData.suggestedNumbers[button]) &&
          <ChipItem
            isMobile={isMobile}
            // as="div"
            ref={(ref) => addChipRef(ref, button)}
            color={chipColor(submitData.suggestedNumbers[button])}
            className="chip"
            isSnapshot={isSnapshot}
            currency={submitData?.paymentMethod?.currency?.asset ?? null}
          >
            <span
              data-value={submitData.suggestedNumbers[button]}
            >
              {formatChip(submitData.suggestedNumbers[button], chipColor(submitData.suggestedNumbers[button]))}
            </span>
          </ChipItem>}
        </StyledRouletteNoOverlayItem>
      ))
    );
  }, [activeCurrency, addChipRef, isMobile, selectNumbers, submitData?.suggestedNumbers, chipColor, hoverEffect, isOverlay]);

  const renderSplitItemV = useCallback((value) => {
    return (
      value.map((button, key) => {
        if ((key % 2) === 0) {
          return (
            <StyledRouletteNoOverlayItem
              isMobile={isMobile}
              onMouseOver={isOverlay ? () => hoverEffect(button, true) : null}
              onMouseOut={isOverlay ? () => hoverEffect(button, false) : null}
              type="corner"
              key={button}
              data={button}
              onClick={isOverlay ? () => selectNumbers(button) : null}
              onContextMenu={isOverlay ? () => selectNumbers(button, true) : null}
            >
              {(isOverlay && submitData.suggestedNumbers[button]) &&
              <ChipItem
                isMobile={isMobile}
                // as="div"
                ref={(ref) => addChipRef(ref, button)}
                color={chipColor(submitData.suggestedNumbers[button])}
                className="chip"
                isSnapshot={isSnapshot}
                currency={submitData?.paymentMethod?.currency?.asset ?? null}
              >
                <span
                  data-value={submitData.suggestedNumbers[button]}
                >
                  {formatChip(submitData.suggestedNumbers[button], chipColor(submitData.suggestedNumbers[button]))}
                </span>
              </ChipItem>}
            </StyledRouletteNoOverlayItem>
          );
        } else {
          return (
            <StyledRouletteNoOverlayItem
              isMobile={isMobile}
              onMouseOver={isOverlay ? () => hoverEffect(button, true) : null}
              onMouseOut={isOverlay ? () => hoverEffect(button, false) : null}
              type="splitV"
              key={button}
              data={button}
              onClick={isOverlay ? () => selectNumbers(button) : null}
              onContextMenu={isOverlay ? () => selectNumbers(button, true) : null}
            >
              {(isOverlay && submitData.suggestedNumbers[button]) &&
              <ChipItem
                isMobile={isMobile}
                // as="div"
                ref={(ref) => addChipRef(ref, button)}
                color={chipColor(submitData.suggestedNumbers[button])}
                className="chip"
                isSnapshot={isSnapshot}
                currency={submitData?.paymentMethod?.currency?.asset ?? null}
              >
                <span
                  data-value={submitData.suggestedNumbers[button]}
                >
                  {formatChip(submitData.suggestedNumbers[button], chipColor(submitData.suggestedNumbers[button]))}
                </span>
              </ChipItem>}
            </StyledRouletteNoOverlayItem>
          );
        }
      })
    );
  }, [activeCurrency, addChipRef, isMobile, selectNumbers, submitData?.suggestedNumbers, chipColor, hoverEffect, isOverlay]);

  useImperativeHandle(ref, () => ({
    rotateTo: (
      number,
      isAnimationDisbaled = false,
      isAutoPlay = false,
      onSelectNumber = null,
    ) => rouletteRef.current.rotateTo(number, isAnimationDisbaled, isAutoPlay, onSelectNumber),
  }), [rouletteRef]);

  return (
    <div className={!isOverlay ? 'num-wrapper' : 'num-wrapper num-wrapper-overlay'}>
      <StyledRouletteItem
        key={0}
        data={0}
        onClick={() => selectNumbers(0)}
        onContextMenu={() => selectNumbers(0, true)}
        onMouseOver={isOverlay ? () => hoverEffect('0', true) : null}
        onMouseOut={isOverlay ? () => hoverEffect('0', false) : null}
        zero
        isMobile={isMobile}
        isOverlay={isOverlay}
        isSnapshot={isSnapshot}
      >
        0
        {submitData.suggestedNumbers[0] &&
        <ChipItem
          isMobile={isMobile}
          // as="div"
          ref={(ref) => addChipRef(ref, '0')}
          color={chipColor(submitData.suggestedNumbers[0])}
          className="chip"
          isSnapshot={isSnapshot}
          currency={submitData?.paymentMethod?.currency?.asset ?? null}
        >
          <span
            data-value={submitData.suggestedNumbers[0]}
          >
            {formatChip(submitData.suggestedNumbers[0], chipColor(submitData.suggestedNumbers[0]))}
          </span>
        </ChipItem>}
        {
          parseInt(selectedNumber) === 0 && (
            <StyledWinningBall
              isMobile={isMobile}
              zero
            >
              <span>
                0
              </span>
            </StyledWinningBall>
          )
        }
      </StyledRouletteItem>
      {renderMainItem(textRates.FIRST_LINE[0])}
      {render2to1Item(textRates.FIRST_LINE[0])}
      {renderMainItem(textRates.SECOND_LINE[0])}
      {render2to1Item(textRates.SECOND_LINE[0])}
      {renderMainItem(textRates.THIRD_LINE[0])}
      {render2to1Item(textRates.THIRD_LINE[0], true)}

      <StyledRouletteConstWrapper
        isMobile={isMobile}
      >
        {renderConstItem(textRates.FIRST_TWELVE[0], "1st 12", "w-3")}
        {renderConstItem(textRates.SECOND_TWELVE[0], "2nd 12", "w-3")}
        {renderConstItem(textRates.THIRD_TWELVE[0], "3rd 12", "w-3")}
        {/* renderConstItem('', "", "w-3 w-3-border") */}
        {renderConstItem(textRates.FIRST_HALF[0], "1-18", "w-6")}
        {renderConstItem(textRates.EVEN[0], "Even", "w-6")}
        {renderConstItem(textRates.BLACK[0], <StyledRedOrBlackIcon isBlack />, "w-6")}
        {renderConstItem(textRates.RED[0], <StyledRedOrBlackIcon />, "w-6")}
        {renderConstItem(textRates.ODD[0], "Odd", "w-6")}
        {renderConstItem(textRates.SECOND_HALF[0], "19-36", "w-6")}
      </StyledRouletteConstWrapper>

      <StyledRouletteNoOverlay
        isMobile={isMobile}
        orientationLandscape={orientation === "landscape"}
      >
        <div className="clear" />
        {renderSplitItemH(textRates.SPLIT_FIRST_LINE)}
        <div className="clear" />
        {renderSplitItemV(textRates.SPLIT_SECOND_LINE)}
        {renderSplitItemH(textRates.SPLIT_THIRD_LINE)}
        <div className="clear" />
        {renderSplitItemV(textRates.SPLIT_FOUR_LINE)}
        {renderSplitItemH(textRates.SPLIT_FIVE_LINE)}
        <div className="clear" />
        {renderSplitItemV(textRates.SPLIT_SIX_LINE)}
      </StyledRouletteNoOverlay>
    </div>
  );
});

export default RouletteTable;
