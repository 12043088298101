import {useState, useEffect, useMemo, useRef, useCallback} from "react";
import { useBetween } from "use-between";
import { useAutoBetsStatesRoulette } from "../../elements/autoBets/AutoBetsStates";
import BalanceStates from "../BalanceStates";
import * as SessStorage from '../../../utils/sessionStorage';
import CurrentStatisticsStates from "../CurrentStatisticStates";
import { useAnimationSync } from '../AnimationSyncDataStates';
import { chipsRef } from './constants';
import {playSound} from "../../../utils/checkSound";
import useSound from "use-sound";
import longSound from "../../../assets/sounds/roulette/roulette-win.mp3";
import autoSound from "../../../assets/sounds/roulette/roulette-auto.mp3";
import {formatNumber} from "../../../utils/formatNumber";
import Big from 'big.js';

const delay = (timeout) => new Promise((resolve) => setTimeout(() => resolve(), timeout));

const RouletteStates = () => {
  const [submitData, setSubmitData] = useState({
      game: "roulette",
      suggestedNumbers: {},
      bet: "0",
      currentBet: "0.00000010"
    }
  );
  const {
    updateStatistics
  } = useBetween(CurrentStatisticsStates);

  const {
    stopAnimationSync
  } = useAnimationSync();

  const [selectedNumber, setSelectedNumber] = useState(undefined);

  const [isRotate, setIsRotate] = useState(false);
  const [settings, setSettings] = useState();
  const [responseData, setResponseData] = useState({
    win: 0
  });

  const [animationDisabled, setAnimationDisabled] = useState((localStorage.getItem(`animationDisabled`) && localStorage.getItem(`animationDisabled`) !== "undefined") ? localStorage.getItem(`animationDisabled`) === 'true' : false);
  const [isPlay, setIsPlay] = useState(false);
  const rouletteTableRef = useRef();
  const rollPaymentMethodRef = useRef();

  const { autoModeIsStart, autoMode, gameInfoRef } = useAutoBetsStatesRoulette();

  const { balance, setBalance, balanceSync, setBalanceSync } = useBetween(BalanceStates);

  const autoModeAcceleration = useMemo(() => autoMode?.acceleration, [autoMode]);

  const [playLongSound, { stop }] = useSound(longSound);
  const [playAutoMode] = useSound(autoSound);

  useEffect(() => {
    if (gameInfoRef.current) {
      gameInfoRef.current.isPlay = isPlay;
    }
  }, [gameInfoRef, isPlay]);

  const resId = useMemo(() => responseData?.id || 'noid', [responseData]);

  useEffect(() => {
    if (responseData?.drawnNumber === undefined) {
      return;
    }
    if (!autoModeIsStart && autoModeAcceleration === 0 && gameInfoRef.current.animationCount > 0) {
      return;
    }
    if (responseData && isPlay) {
      (async () => {
        try {
          gameInfoRef.current.isFullReset = false;
          gameInfoRef.current.animationCount++;
          (autoModeIsStart || animationDisabled) ? playSound(playAutoMode) : playSound(playLongSound)
          const { drawnNumber } = responseData;
          let newBalance = parseFloat(new Big(formatNumber(rollPaymentMethodRef.current.getBalanceInputValue())).minus(new Big(formatNumber(responseData.bet))));
          const oldBalance = parseFloat(new Big(formatNumber(rollPaymentMethodRef.current.getBalanceInputValue())).minus(new Big(formatNumber(responseData.bet))));
          if ((!animationDisabled && !autoModeIsStart) || (!animationDisabled && autoModeIsStart && autoModeAcceleration === 0)) {
            (async () => {
              // console.log('rollPaymentMethodRef.current.updatBalanceInputValue0');
              try {
                await rollPaymentMethodRef.current.updatBalanceInputValue(newBalance, (((autoModeIsStart && autoModeAcceleration > 0) || animationDisabled) ? 0 : 500));
              } catch (error) {
                console.log('error');
                gameInfoRef.current.animationCount--;
                stopAnimationSync();
                setIsPlay(false);
              }
            })();
          }

          if ((autoModeIsStart && autoModeAcceleration > 0) || (animationDisabled && !autoModeIsStart)) {
            stopAnimationSync();
          }

          // console.log('====ROULET ROLLING:', drawnNumber, 'autoModeIsStart:', autoModeIsStart);
          if (!rouletteTableRef.current) {
            // setIsPlay(false);
            gameInfoRef.current.animationCount--;
            stopAnimationSync();
            return;
          }
          await rouletteTableRef.current.rotateTo(
            drawnNumber,
            animationDisabled,
            autoModeIsStart,
            (selectNum) => {
              // выделяем номер в таблице
              setSelectedNumber(selectNum);
            },
            autoModeAcceleration,
          );
          // console.log('====END ROULET ROLLING:', drawnNumber, 'autoModeIsStart:', autoModeIsStart);

          // сохранем ставку
          const { suggestedNumbers } = submitData;

          // фильтруем ставки, оставляем только выигрышные
          let isWin = false;
          const winningSuggestedNumbers = {};
          Object.keys(suggestedNumbers).forEach((numbersString) => {
            const numbers = numbersString.split(',');
            if (numbers.indexOf(`${drawnNumber}`) >= 0) {
              winningSuggestedNumbers[numbersString] = suggestedNumbers[numbersString];
              isWin = true;
            }
          });

          if ((!animationDisabled && !autoModeIsStart) || (!animationDisabled && autoModeIsStart && autoModeAcceleration === 0)) {
            // если у нас анимация включена оставляем в таблице только выигрышные фишки
            setSubmitData((prevState) => ({ ...prevState, "suggestedNumbers": winningSuggestedNumbers}));
          }

          if (isWin) {

            const keys = Object.keys(winningSuggestedNumbers);

            // берем позицию баланса на экране
            let inputRect = null;
            if (rollPaymentMethodRef.current) {
              inputRect = rollPaymentMethodRef.current.getBalanceInputBoundingClientRect(newBalance);
            }
            let animList = [];
            let i = 0;
            let chipRef;

            if ((!animationDisabled && !autoModeIsStart) || (!animationDisabled && autoModeIsStart && autoModeAcceleration === 0)) {
              const newChipValue = (balance - newBalance) / keys.length;
              // анимация обновления значения фишек
              for (i = 0; i < keys.length; i++) {
                chipRef = chipsRef.current[keys[i]];
                // chipRef.parent = document;
                animList.push(chipRef.updateBetValue(newChipValue, 500));
              }

              if (animList.length > 0) {
                await Promise.all(animList);
              }

              animList = [];
              // анимация перемещения фишек
              for (i = 0; i < keys.length; i++) {
                chipRef = chipsRef.current[keys[i]];
                if (chipRef && inputRect) {
                  // берем позицию фишки на экране и считаем смещение для анимации переноса фишек к балансу
                  const chipRect = chipRef.getBoundingClientRect();
                  const offsetX = (inputRect.width - chipRect.width) / 2;
                  const dx = (inputRect.left - chipRect.left) + offsetX;
                  const dy = inputRect.top - chipRect.top;
                  animList.push(chipRef.moveTo(dx, dy, 2000));
                }
              }

              if (animList.length > 0) {
                await Promise.all(animList);
              }
            }

            if ((!animationDisabled && !autoModeIsStart) || (!animationDisabled && autoModeIsStart && autoModeAcceleration === 0)) {
              // прячем выигрышные фишки
              for (i = 0; i < keys.length; i++) {
                chipRef = chipsRef.current[keys[i]];
                chipRef.visible(false);
              }

              // задержка
              await delay(40);

              // обновляем баланс тем, что пришел с сервера
              newBalance = balance;
              // console.log('rollPaymentMethodRef.current.updatBalanceInputValue1');
              if (rollPaymentMethodRef.current) {
                await rollPaymentMethodRef.current.updatBalanceInputValue(newBalance, (((autoModeIsStart && autoModeAcceleration > 0) || animationDisabled) ? 0 : 500), oldBalance);
              }
              // задержка
              await delay(40);

              // сбрасываем анимацию фишек в начальную позицию
              for (i = 0; i < keys.length; i++) {
                chipRef = chipsRef.current[keys[i]];
                chipRef.reset();
              }

              // задержка
              await delay(40);

              // показываем выигрышные фишки
              for (i = 0; i < keys.length; i++) {
                chipRef = chipsRef.current[keys[i]];
                chipRef.visible(true);
              }
            }
          } else {
            if (!autoModeIsStart && !animationDisabled) stop()
            // if ((!animationDisabled && !autoModeIsStart) || (!animationDisabled && autoModeIsStart && autoModeAcceleration === 0)) {
              /*
              (async () => {
                newBalance = balanceSync;
                console.log('rollPaymentMethodRef.current.updatBalanceInputValue2');
                if (rollPaymentMethodRef.current) {
                  await rollPaymentMethodRef.current.updatBalanceInputValue(newBalance, ((autoModeIsStart && autoModeAcceleration > 0) || animationDisabled) ? 0 : 500);
                }
              })();
              */
              if (!autoModeIsStart || (autoModeIsStart && autoModeAcceleration === 0 && !animationDisabled)) {
                newBalance = balance;
                // console.log('rollPaymentMethodRef.current.updatBalanceInputValue2');
                if (rollPaymentMethodRef.current) {
                  await rollPaymentMethodRef.current.updatBalanceInputValue(newBalance, (((autoModeIsStart && autoModeAcceleration > 0) || animationDisabled) ? 0 : 500), oldBalance);
                }
              }

            // }
          }

          gameInfoRef.current.animationCount--;
          // await delay(100);
          // игра окончена
          setIsPlay(false);



          if (suggestedNumbers) {
            let sum = 0;
            for (let bet of Object.values(suggestedNumbers)) {
              sum += bet;
            }
            SessStorage.setItem("RoulettePrevBet", { suggestedNumbers,  bet: sum });
          }
          if (!autoModeIsStart && animationDisabled) await delay(500);

          if ((!autoModeIsStart) || (!animationDisabled && autoModeIsStart && autoModeAcceleration === 0)) {
            // возвращаем состояние фишек в таблице для новой игры
            // setSubmitData((prevState) => ({ ...prevState, "suggestedNumbers": suggestedNumbers }));

            // очищаем стол от фишек
            setSubmitData((prevState) => ({ ...prevState, "suggestedNumbers": {}, bet: "0" }));

            if (!autoModeIsStart && animationDisabled) await delay(500);

            // сбрасываем веделение выпашвего номера в таблице
            setSelectedNumber(undefined);

            // сбрасываем выпашвий номер, чтоб ложно не срабатывал запуск анимации рулетки
            setResponseData((prevState) => ({ ...prevState, drawnNumber: undefined, win: Number(0).toFixed(8) }));

            console.log('firstReset!!!');
            gameInfoRef.current.isFullReset = true;
          } else {
            // сбрасываем выпашвий номер, чтоб ложно не срабатывал запуск анимации рулетки
            setResponseData((prevState) => ({ ...prevState, drawnNumber: undefined }));
          }

          stopAnimationSync();
        } catch (error) {
          stopAnimationSync();
          gameInfoRef.current.animationCount = 0;
          setIsPlay(false);
        }
      })();
    }
  }, [isPlay, resId, animationDisabled, autoModeIsStart, autoModeAcceleration, updateStatistics, stopAnimationSync, gameInfoRef]);

  const [errorData, setErrorData] = useState({
    error: false,
    terminate: false,
  });

  const resetBet = useCallback(() => {
    if (!gameInfoRef.current.isFullReset) {
      console.log('second resetBet!!!!');
      setSubmitData((prevState) => ({ ...prevState, "suggestedNumbers": {}, bet: "0" }));
      setSelectedNumber(undefined);
    }
  }, [gameInfoRef]);

  useEffect(() => {
    localStorage.setItem("animationDisabled", animationDisabled.toString());
  }, [animationDisabled])

  return {
    submitData,
    setSubmitData,
    isRotate,
    setIsRotate,
    settings,
    setSettings,
    responseData,
    setResponseData,
    errorData,
    setErrorData,
    setAnimationDisabled,
    animationDisabled,
    isPlay,
    setIsPlay,
    selectedNumber,
    setSelectedNumber,
    rouletteTableRef,
    rollPaymentMethodRef,
    autoModeIsStart,
    resetBet,
  };
}

export default RouletteStates;
